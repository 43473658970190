// import React, { useState, memo, useEffect } from "react";
// import PropTypes from "prop-types";
// import Dropzone from "react-dropzone";
// import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";

// // Actions
// import { uploadFile } from "store/actions";

// function DropZone(props) {
//   const dispatch = useDispatch();
//   const [imageFile, setimageFile] = useState({ file: null, url: null, baseUrl: null });

//   useEffect(() => {
//     if (props.file) {
//       // console.log("props", props.file)
//       setimageFile((pre) => ({
//         ...pre,
//         url: props.file?.url,
//         baseUrl: props?.file?.baseUrl
//       }));
//     }
//   }, [props.file]);

//   const handleFiles = (_files) => {
//     const files = _files.filter((file) => file.size < 10e6);

//     if (files.length < _files.length)
//       return toast.error("Max file upload size is 5mb");

//     const callback = (err, res) => {
//       console.log(err, res, "<===err, res");
//       if (err) {
//         toast.error("Something went wrong on file upload!");
//       } else {
//         props.onChange({ target: { value: res?.data?.url, baseUrl: res?.data?.baseUrl } });
//         setimageFile((pre) => ({
//           ...pre,
//           baseUrl: res?.data?.baseUrl
//         }));
//       }
//     };
//     // console.log(files[0], "<===file");
//     dispatch(uploadFile({ data: files[0], callback }));

//     // console.log(URL.createObjectURL(files[0]), "<===obj url");

//     setimageFile((pre) => ({
//       ...pre,
//       file: files[0],
//       url: URL.createObjectURL(files[0]),
//       baseUrl: null
//     }));

//     // console.log(URL.createObjectURL(files[0]), "<===URL.createObjectURL(files[0])");
//   };
//   // console.log("image", imageFile?.baseUrl, imageFile?.url)
//   return (
//     <Dropzone onDrop={handleFiles}>
//       {({ getRootProps, getInputProps }) => (
//         <div className="dropzone-single-image avatar-xl" {...getRootProps()}>
//           <input
//             {...getInputProps()}
//             id="formrow-profile-image-Input"
//             multiple={false}
//           />

//           {props.children ? (
//             props.children
//           ) : imageFile?.file?.type?.includes("video") ||
//             imageFile?.url?.includes("video") ? (
//             <video
//               width={"100%"}
//               height={120}
//               controls
//               autoPlay={true}
//               loop
//               className="video_preview"
//             >
//               <source src={imageFile?.baseUrl? imageFile?.baseUrl : "" + imageFile.url} type={"video/mp4"} />
//             </video>
//           ) : (
//             <>
//               <img
//                 className="rounded avatar-xl"
//                 alt={""}
//                 src={
//                   !!imageFile.url && !!imageFile.baseUrl
//                     ? imageFile.baseUrl + imageFile.url
//                     : "/assets/img/logo-placeholder.png"
//                 }
//                 width={"100%"}
//                 height={"150px"}
//               />

//               <div className="edit">
//                 <i className="bx bx-pencil"></i>
//               </div>
//             </>
//           )}
//         </div>
//       )}
//     </Dropzone>
//   );
// }

// DropZone.propTypes = {
//   file: PropTypes.string,
//   onChange: PropTypes.func,
// };

// export default memo(DropZone);
import React, { useState, memo, useEffect } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Actions
import { uploadFile } from "store/actions";

function DropZone(props) {
  const dispatch = useDispatch();
  const [imageFile, setimageFile] = useState({ file: null, url: null, baseUrl: null });

  const ImageBaseUrl=process.env.REACT_APP_IMAGE_BASEURL
  useEffect(() => {
    if (props.file) {
      // console.log("props", props.file)
      setimageFile((pre) => ({
        ...pre,
        url: props.file?.url,
        baseUrl: props?.file?.baseUrl
      }));
    }
  }, [props.file]);

  const handleFiles = (_files) => {
    const files = _files.filter((file) => file.size < 10e6);

    if (files.length < _files.length)
      return toast.error("Max file upload size is 5mb");

    const callback = (err, res) => {
      console.log(err, res, "<===err, res");
      if (err) {
        toast.error("Something went wrong on file upload!");
      } else {
        props.onChange({ target: { value: res?.data?.url, baseUrl: res?.data?.baseUrl } });
        setimageFile((pre) => ({
          ...pre,
          baseUrl: res?.data?.baseUrl,
          url: res?.data?.url
        }));
      }
    };
    // console.log(files[0], "<===file");
    dispatch(uploadFile({ data: files[0], callback }));

    // console.log(URL.createObjectURL(files[0]), "<===obj url");

    setimageFile((pre) => ({
      ...pre,
      file: files[0],
      url: URL.createObjectURL(files[0]),
      baseUrl: null
    }));

    // console.log(URL.createObjectURL(files[0]), "<===URL.createObjectURL(files[0])");
  };
  console.log("image", imageFile?.baseUrl, imageFile?.url)
  return (
    <Dropzone onDrop={handleFiles}>
      {({ getRootProps, getInputProps }) => (
        <div className="dropzone-single-image avatar-xl" {...getRootProps()}>
          <input
            {...getInputProps()}
            id="formrow-profile-image-Input"
            multiple={false}
          />

          {props.children ? (
            props.children
          ) : imageFile?.file?.type?.includes("video") ||
            imageFile?.url?.includes("video") ? (
            <video
              width={"100%"}
              height={120}
              controls
              autoPlay={true}
              loop
              className="video_preview"
            >
              <source src={imageFile?.baseUrl? imageFile?.baseUrl : "" + imageFile.url} type={"video/mp4"} />
            </video>
          ) : (
            <>
              <img
                className="rounded avatar-xl"
                alt={""}
                src={
                  imageFile.url 
                    ? `${ImageBaseUrl}` + imageFile.url
                    : "/assets/img/logo-placeholder.png"
                }
                width={"100%"}
                height={"150px"}
              />

              <div className="edit">
                <i className="bx bx-pencil"></i>
              </div>
            </>
          )}
        </div>
      )}
    </Dropzone>
  );
}

DropZone.propTypes = {
  file: PropTypes.string,
  onChange: PropTypes.func,
};

export default memo(DropZone);
