import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { DASHBOARD } from "common/viewContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartColumn,
  faQrcode,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import ReactApexChart from "react-apexcharts";
import { getDashboard } from "store/dashboard/actions";
import { FadeLoader } from "react-spinners";
const Dashboard = (props) => {
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState(null);
  useEffect(() => {
    dispatch(getDashboard()).then((response) => {
      if (response.payload.data) {
        setDashboardData(response.payload.data);
      }
    });
  }, [dispatch]);
  const chartOptions = {
    chart: {
      type: "line",
    },
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    tooltip: {
      enabled: true,
    },
    title: {
      text: "Total Users Trends by Month",
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: ["#7b6ee1"],
    series: [
      {
        name: "users ",
        data: dashboardData?.userGraph?.map((it) => it.count),
      },
    ],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };
  return (
    <>
      {dashboardData?.length && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={DASHBOARD.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{DASHBOARD.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item">Welcome to Pamietac admin ! </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Link to={"/admin/users"}>
              <div className="card card-statistic-2 p-4 shadow-sm bg-gradient rounded-lg hover-card">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <span className="fw-bolder">Total Registered : </span>
                    <span className="fw-bolder">
                      {dashboardData?.totalUsers}
                    </span>
                    <br />
                  </div>
                  <div className="col-md-6 d-flex justify-content-end align-items-center">
                    <div className="mr-3">
                      <FontAwesomeIcon
                        color="blue"
                        size="2x"
                        style={{ marginRight: "10px" }}
                        icon={faUsers}
                        className="icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Link to={"/admin/users"}>
              <div className="card card-statistic-2 p-4 shadow-sm bg-gradient rounded-lg hover-card">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <span className="fw-bolder">Total Profiles : </span>
                    <span className="fw-bolder">
                      {dashboardData?.totalProfiles}
                    </span>
                    <br />
                  </div>
                  <div className="col-md-6 d-flex justify-content-end align-items-center">
                    <div className="mr-3">
                      <FontAwesomeIcon
                        color="blue"
                        size="2x"
                        style={{ marginRight: "10px" }}
                        icon={faUsers}
                        className="icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Link to={"/admin/qrcreate"}>
              <div className="card card-statistic-2 p-4 shadow-sm bg-gradient rounded-lg hover-card">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <span className="fw-bolder">Total QR Plates: </span>
                    <span className="fw-bolder">
                      {dashboardData?.totalQRPlates}
                    </span>
                    <br />
                  </div>
                  <div className="col-md-6 d-flex justify-content-end align-items-center">
                    <div className="mr-3">
                      <FontAwesomeIcon
                        icon={faQrcode}
                        className="icon"
                        color="green"
                        size="2x"
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Link to={"/admin/qrcreate"}>
              <div className="card card-statistic-2 p-4 shadow-sm bg-gradient rounded-lg hover-card">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <span className="fw-bolder">Linked QR Plates: </span>
                    <span className="fw-bolder">
                      {dashboardData?.linkedQRPlates}
                    </span>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end align-items-center">
                    <div className="mr-3">
                      <FontAwesomeIcon
                        icon={faQrcode}
                        className="icon"
                        color="green"
                        size="2x"
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card card-statistic-2 p-4 shadow-sm bg-gradient rounded-lg hover-card">
              <div className="row  align-items-center justify-content-between">
                <div className="col-md-6 ">
                  {/* <span className="fw-bolder col-6 ">Revenue:</span>
                  <span className="fw-bolder col-6 rounded-5 border-2 border-danger ">
                    $.{dashboardData?.totalRevenueInUSD}
                  </span> */}
                  <span className="fw-bolder col-6 ">Revenue:</span>
                  <span className="fw-bolder col-6 rounded-5 border-2 border-danger ">
                    ₾{dashboardData?.totalRevenueInGEL || 0}
                  </span>
                </div>
                <div className="col-md-6 d-flex justify-content-end align-items-center">
                  <div className="mr-3">
                    <FontAwesomeIcon
                      color="red"
                      size="2x"
                      style={{ marginRight: "10px" }}
                      icon={faChartColumn}
                      className="icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <ReactApexChart
            options={chartOptions}
            series={chartOptions.series}
            type="line"
            height={350}
          />
          <p className="d-flex justify-content-center align-items-center p-4 ">
            Registered All User Chart
          </p>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.authToken,
    user: state.Auth.Auth,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
